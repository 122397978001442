<template>
  <div class="wx">
    <img class="wx-arrow" src="../assets/images/arrow-up.svg" alt="" />
    <div class="wx-card">
      <img src="../assets/images/card.svg" alt="" />
      <p>支付宝支付<br>请点击右上角&nbsp;•••<br>复制链接到手机浏览器打开<br>苹果手机请使用Safari浏览器</p>
    </div>
      <br>
    <div class="wx-btn">
      <Button @click="handleChangeToWx" type="primary" round size="large" icon="wechat">微信支付</Button>
    </div>
  </div>
</template>

<script>
import {Button} from "vant";
export default {
  name: "wx",
  components:{
    Button
  },
  methods:{
    handleChangeToWx(){
      this.$emit("changePay")
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.wx {
  width: 100%;
  height: 100%;
  position: relative;
  &-arrow {
    display: block;
    width: 38px;
    position: absolute;
    right: 28px;
    top: 24px;
  }
  &-btn{
    padding: 0 60px;
  }
  &-card {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 108px;
    img {
      display: block;
      width: 156px;
      margin-bottom: 37px;
    }
    p {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
    }
  }
}
</style>
